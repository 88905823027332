import {OnDestroy} from '@angular/core';
import {Router, NavigationEnd, ActivatedRouteSnapshot, ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil, filter, tap} from 'rxjs/operators';
import {QuickbooksService} from '../services/quickbooks.service';
import {AuthenticationService} from '../services/authentication.service';
import { Google } from '../../environments/environment';
import {map} from 'rxjs/internal/operators';


declare var window: any;

export abstract class RouterPage implements OnDestroy {

    private ngUnsubscribe: Subject<void> = new Subject();


    private qbService: QuickbooksService;
    private aService: AuthenticationService;

    constructor(router: Router, route: ActivatedRoute) {

        router.events.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(event => event instanceof NavigationEnd),
            filter(_ => this._isComponentActive(
                router.routerState.snapshot.root.pathFromRoot,
                route.snapshot.component
            )),
        ).subscribe(event => {
            this._analytics(event);
            this._siftEvent(event);
            this.onEnter();
        });


    }



    private _siftEvent(event) {
        ;(async () => {
            if (window._sift) {
                console.log("Anti-fraud detection enabled")
                window._sift.push(['_trackPageview']);
            }
        })()
    }

    private _analytics(event): boolean {

        (<any>window).gtag('config', Google.analytics, {
            page_title: 'Facepay Business',
            page_path: event.url
        });

        return true;


    }

    private _isComponentActive(path: ActivatedRouteSnapshot[], component: any): boolean {
        let isActive = false;
        path.forEach((ss: ActivatedRouteSnapshot) => {
            if (ss.component === component) {
                isActive = true;
            } else {
                isActive = this._isComponentActive(ss.children, component);
            }
        });

        return isActive;
    }


    abstract onEnter(): void;

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
