import { Component, OnInit } from '@angular/core';
import {Auth0BusinessService} from '../../services/auth0/auth0business.service';
import {RouterPage} from '../../abstract/RouterPage';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-auth0-callback',
  templateUrl: './auth0-callback.component.html',
  styleUrls: ['./auth0-callback.component.scss'],
})
export class Auth0CallbackComponent extends RouterPage implements OnInit {

    constructor(private auth0Service: Auth0BusinessService,
                private router: Router,
                private route: ActivatedRoute) {

        super(router, route);


    }
    ngOnInit() {
    }
    onEnter(){


        try {
            this.auth0Service.handleAuthCallback()
        } catch (e) {
            console.dir(e);
            this.router.navigate(['logout']);
        }
    }

}
