// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare var require: any
const { version: appVersion } = require('../../package.json')

export const environment2 = {
  production: false,

};
//    domain: 'http://192.168.7.45:8100',
//    api: 'wss://qbo.dev.facepay.io/api',
//    api: 'wss://api.facepay.io:3000'



export const Dictionary = {

    "default": {
        business: "Business",
        customer: "Customer",
        customers: "Customers",
        invoice: "Invoice",
        "businessoption":{
            texttopay: "Thank you for choosing {{BusinessName}}.  A payment of {{Amount}} is due for your service. For your convenience of an expedited, contactless pickup, please go here:\n\n{{Link}}\n\nYou can easily sign up if using for the first time or login to pay your bill.",
            textlink: "`Welcome to {{BusinessName}}. Please take a moment to create an account for updates as your service progresses. You can use this link to get started: \n\n{{Link}}\n\nWe’ll be in touch with you soon! Reply STOP to opt-out." ,
        },
    },

    "Automotive": {
        business: "Auto Shop",
        customer: "Customer",
        customers: "Customers",
        invoice: "Repair Order",
        "businessoption":{
            texttopay: "Thank you for choosing {{BusinessName}}. A payment of {{Amount}} is due for your service. For your convenience of an expedited pickup, please go here:\n\n{{Link}}\n\nYou can easily sign up if using for the first time or login to pay your bill.",
            textlink:  "Welcome to {{BusinessName}}. Please take a moment to create an account for updates as your service progresses. You can use this link to get started: \n\n{{Link}}\n\nWe’ll be in touch with you soon! Reply STOP to opt-out.",
        }
    },
    "Dental": {
        business: "Business",
        customer: "Patient",
        customers: "Patients",
        invoice: "Invoice",
        "businessoption":{
            texttopay: "Thank you for choosing {{BusinessName}}.  A payment of {{Amount}} is due for your service. For your convenience of an expedited, contactless pickup, please go here:\n\n{{Link}}\n\nYou can easily sign up if using for the first time or login to pay your bill.",
            textlink: "Welcome to {{BusinessName}}. Please take a moment to create an account for updates as your service progresses. You can use this link to get started: \n\n{{Link}}\n\nWe’ll be in touch with you soon! Reply STOP to opt-out."
        }
    },
    "Spa": {
        business: "Business",
        customer: "Guest",
        customers: "Guests",
        invoice: "Invoice",
        "businessoption":{
            texttopay: "Thank you for choosing {{BusinessName}}.  A payment of {{Amount}} is due for your service. For your convenience of an expedited, contactless pickup, please go here:\n\n{{Link}}\n\nYou can easily sign up if using for the first time or login to pay your bill.",
            textlink:  "Welcome to {{BusinessName}}. Please take a moment to create an account for updates as your service progresses. You can use this link to get started: \n\n{{Link}}\n\nWe’ll be in touch with you soon! Reply STOP to opt-out."
        }
    },
    "Salon": {
        business: "Business",
        customer: "Guest",
        customers: "Guests",
        invoice: "Invoice",
        "businessoption":{
            texttopay: "Thank you for choosing {{BusinessName}}.  A payment of {{Amount}} is due for your service. For your convenience of an expedited, contactless pickup, please go here:\n\n{{Link}}\n\nYou can easily sign up if using for the first time or login to pay your bill.",
            textlink:  "Welcome to {{BusinessName}}. Please take a moment to create an account for updates as your service progresses. You can use this link to get started: \n\n{{Link}}\n\nWe’ll be in touch with you soon! Reply STOP to opt-out."
        }
    },
    "Lifestyle": {
        business: "Business",
        customer: "Guest",
        customers: "Guests",
        invoice: "Invoice",
        "businessoption":{
            texttopay: "Thank you for choosing {{BusinessName}}.  A payment of {{Amount}} is due for your service. For your convenience of an expedited, contactless pickup, please go here:\n\n{{Link}}\n\nYou can easily sign up if using for the first time or login to pay your bill.",
            textlink:  "Welcome to {{BusinessName}}. Please take a moment to create an account for updates as your service progresses. You can use this link to get started: \n\n{{Link}}\n\nWe’ll be in touch with you soon! Reply STOP to opt-out."
        }
    }
}


export const environment = {
    production: false,
    version: appVersion

};


export const FacepayProd= {
    quickbooks: 'https://qbo.facepay.io',
    amazon: 'https://amazon.facepay.io',



    domain: 'https://login.facepay.io',
    api_direct: 'wss://api.facepay.io',


    api: 'wss://proxy.facepay.io/api',
    twilio: 'https://proxy.facepay.io/twilio',
    dwollaTokens: 'https://dwolla.facepay.io',

    dwolla: 'https://proxy.facepay.io/dwolla',
    qr: 'https://dwolla.facepay.io',

    plaid: 'https://proxy.facepay.io/plaid',
    services: 'https://proxy.facepay.io/services',
    sift: 'https://proxy.facepay.io/services',
    link: 'https://proxy.facepay.io/link',


    plaid_env: 'production',//'development',
    plaid_key: '312ca18ffbec548c2fa4fd39518c29',


    plaid_salesforce_env: 'sandbox',
    plaid_salesforce_key: '312ca18ffbec548c2fa4fd39518c29',

    siftBeacon: '1963481312',
    api_shopware_start: 0,
    api_shopware_end: 180,



    guest : {
        limit: 100
    }


};
export const FacepayBackupProd= {
    quickbooks: 'https://qbo.facepay.io',
    amazon: 'https://amazon.facepay.io',



    domain: 'https://backup.facepay.io',
    api_direct: 'wss://api.facepay.io',


    api: 'wss://proxy-backup.facepay.io/api',
    twilio: 'https://proxy-backup.facepay.io/twilio',
    dwollaTokens: 'https://dwolla.facepay.io',

    dwolla: 'https://proxy-backup.facepay.io/dwolla',
    qr: 'https://dwolla.facepay.io',

    plaid: 'https://proxy-backup.facepay.io/plaid',
    services: 'https://proxy-backup.facepay.io/services',
    sift: 'https://proxy-backup.facepay.io/services',
    link: 'https://proxy-backup.facepay.io/link',


    plaid_env: 'production',//'development',
    plaid_key: '312ca18ffbec548c2fa4fd39518c29',


    plaid_salesforce_env: 'sandbox',
    plaid_salesforce_key: '312ca18ffbec548c2fa4fd39518c29',

    siftBeacon: '1963481312',

    api_shopware_start: 0,
    api_shopware_end: 180,


    guest : {
        limit: 100
    }


};



export const FacepayDev= {
    quickbooks: 'https://qbo.dev.facepay.io',
    amazon: 'https://amazon.dev.facepay.io',


    domain: 'https://login.dev.facepay.io',
    api_direct: 'wss://api.dev.facepay.io',


    api: 'wss://proxy.dev.facepay.io/api',
    twilio: 'https://proxy.dev.facepay.io/twilio',
    dwollaTokens: 'https://dwolla.dev.facepay.io',
    dwolla: 'https://proxy.dev.facepay.io/dwolla',
    qr: 'https://dwolla.dev.facepay.io',
    plaid: 'https://proxy.dev.facepay.io/plaid',
    services: 'https://proxy.dev.facepay.io/services',
    sift: 'https://proxy.dev.facepay.io/services',
    link: 'https://proxy.dev.facepay.io/link',

    plaid_env: 'sandbox',
    plaid_key: '312ca18ffbec548c2fa4fd39518c29',


    plaid_salesforce_env: 'sandbox',
    plaid_salesforce_key: '312ca18ffbec548c2fa4fd39518c29',

    siftBeacon: '740232d501',

    api_shopware_start: 0,
    api_shopware_end: 180,


    guest : {
        limit: 100
    }


};
export const FacepayBackupDev= {
    quickbooks: 'https://qbo.dev.facepay.io',
    amazon: 'https://amazon.dev.facepay.io',


    domain: 'https://backup.dev.facepay.io',
    api_direct: 'wss://api.dev.facepay.io',


    api: 'wss://proxy-backup.dev.facepay.io/api',
    twilio: 'https://proxy-backup.dev.facepay.io/twilio',
    dwollaTokens: 'https://dwolla.dev.facepay.io',
    dwolla: 'https://proxy-backup.dev.facepay.io/dwolla',
    qr: 'https://dwolla.dev.facepay.io',
    plaid: 'https://proxy-backup.dev.facepay.io/plaid',
    services: 'https://proxy-backup.dev.facepay.io/services',
    sift: 'https://proxy-backup.dev.facepay.io/services',
    link: 'https://proxy-backup.dev.facepay.io/link',

    plaid_env: 'sandbox',
    plaid_key: '312ca18ffbec548c2fa4fd39518c29',


    plaid_salesforce_env: 'sandbox',
    plaid_salesforce_key: '312ca18ffbec548c2fa4fd39518c29',

    siftBeacon: '740232d501',

    api_shopware_start: 0,
    api_shopware_end: 180,


    guest : {
        limit: 100
    }


};

export const Auth0Prod = {

    "redirect_uri_desktoptexttopay": `${window.location.origin}/desktop/direct/texttopay`,

    "clientId": "jp2UReuEO3d3irN3CCTYMAyzFhWgnu82",
    "domain": "facepay.auth0.com",
    "audience": "https://platform.facepay.io",
    "callback_uri": `${window.location.origin}/auth0-callback`,
    "redirect_uri_business": `${window.location.origin}/desktop/home`,
    "redirect_uri_setup": `${window.location.origin}/desktop/setup/setup`,
    "redirect_uri_admin": `${window.location.origin}/test`,
    "redirect_uri_activate": `${window.location.origin}/activate/:bid/code`,
    "redirect_uri_claim": `${window.location.origin}/claim-complete/:uuid`,
    "redirect_uri_affiliatepay": `${window.location.origin}/affiliate/pay`,
    "redirect_uri_affiliateauthorize": `${window.location.origin}/affiliate/authorize?bid=:bid&gid=:gid&bname=:bname&gname=:gname&bgravatar=:bgravatar&ggravatar=:ggravatar`,
    "redirect_uri_affiliateauthorizeadd": `${window.location.origin}/affiliate/authorize-add?bid=:bid&gid=:gid&bname=:bname&gname=:gname&bgravatar=:bgravatar&ggravatar=:ggravatar`,

    "redirect_uri_desktop": `${window.location.origin}/desktop/home`,
    "redirect_uri_desktopaccept": `${window.location.origin}/desktop/public/accept`,




    "redirect_uri_person_verify": `${window.location.origin}/person/:pid/verify/microdeposit`,

    "redirect_uri_guestpurchasetwofactor": `guest/business/:bid/guests/:gid/purchases/:pid/two-factor`,
    "redirect_uri_guestpurchasetwofactorwechat": `${window.location.origin}/guest/business/:bid/guests/:gid/purchases/:pid/two-factor?isPicture=:isPicture&cell=:cell&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly&date=:date&bname=:bname&bgravatar=:bgravatar`,
    "redirect_uri_guestwelcomechooser": `guest/business/:bid/guests/:gid/welcome/chooser`,
    "redirect_uri_guestwelcomeverify": `${window.location.origin}/guest/business/:bid/guests/:gid/welcome/verify`,

    "redirect_uri_guestwelcomechooserwechat": `${window.location.origin}/guest/business/:bid/guests/:gid/welcome/chooser?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly`,
    "redirect_uri_guestwelcomesignup": `guest/business/:bid/guests/:gid/welcome/signup`,
    "redirect_uri_guestwelcometwofactor": `guest/business/:bid/guests/:gid/welcome/two-factor`,
    "redirect_uri_guestwelcomehome": `guest/business/:bid/guests/:gid/welcome/home?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly`,
    "redirect_uri_guestwelcometwofactorwithparams": `guest/business/:bid/guests/:gid/welcome/two-factor?uuid=:uuid&name=:name&gravatar=:gravatar&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly`,
    "redirect_uri_guestwelcomeplaid": `guest/business/:bid/guests/:gid/welcome/plaid`,
    "redirect_uri_guestwelcomesuccess": `guest/business/:bid/guests/:gid/welcome/success`,
    "redirect_uri_guestwelcomesuccesswechat": `${window.location.origin}/guest/business/:bid/guests/:gid/welcome/success?&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly`,

    "redirect_uri_guestpurchase": `guest/business/:bid/guests/:gid/purchases/:pid`,

    "redirect_uri_guestsignuphooser": `guest/business/:bid/guests/:gid/signup/chooser`,


}
export const Auth0Dev = {

    "redirect_uri_desktoptexttopay": `${window.location.origin}/desktop/direct/texttopay`,


    // "clientId": "7klMP5ouh26MmHMKWLyb6yenSxWmmElD",
    // "domain": "facepay-customer.us.auth0.com",
    // "audience": "https://platform.dev.myfacepay.io",

    "clientId": "KIT3bT9pnl4d9GNPRnk2uuuFEGwZEWIU",
    "domain": "facepay.auth0.com",
    "audience": "https://platform.dev.facepay.io",
    "callback_uri": `${window.location.origin}/auth0-callback`,
    "redirect_uri_business": `${window.location.origin}/desktop/home`,
    "redirect_uri_setup": `${window.location.origin}/desktop/setup/setup`,



    "redirect_uri_admin": `${window.location.origin}/test`,
    "redirect_uri_activate": `${window.location.origin}/activate/:bid/code`,
    "redirect_uri_claim": `${window.location.origin}/claim-complete/:uuid`,
    "redirect_uri_affiliateauthorize": `${window.location.origin}/affiliate/authorize?bid=:bid&gid=:gid&bname=:bname&gname=:gname&bgravatar=:bgravatar&ggravatar=:ggravatar`,
    "redirect_uri_affiliateauthorizeadd": `${window.location.origin}/affiliate/authorize-add?bid=:bid&gid=:gid&bname=:bname&gname=:gname&bgravatar=:bgravatar&ggravatar=:ggravatar`,

    "redirect_uri_affiliatepay": `${window.location.origin}/affiliate/pay`,
    "redirect_uri_desktop": `${window.location.origin}/desktop/home`,
    "redirect_uri_desktopaccept": `${window.location.origin}/desktop/public/accept`,

    "redirect_uri_guestpurchasetwofactor": `guest/business/:bid/guests/:gid/purchases/:pid/two-factor`,
    "redirect_uri_guestpurchasetwofactorwechat": `${window.location.origin}/guest/business/:bid/guests/:gid/purchases/:pid/two-factor?isPicture=:isPicture&cell=:cell&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly&date=:date&bname=:bname&bgravatar=:bgravatar`,
    "redirect_uri_guestwelcomeverify": `${window.location.origin}/guest/business/:bid/guests/:gid/welcome/verify`,
    "redirect_uri_guestwelcomechooser": `guest/business/:bid/guests/:gid/welcome/chooser`,
    "redirect_uri_guestwelcomechooserwechat": `${window.location.origin}/guest/business/:bid/guests/:gid/welcome/chooser?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly`,


    "redirect_uri_person_verify": `${window.location.origin}/person/:pid/verify/microdeposit`,



    "redirect_uri_guestwelcomesignup": `guest/business/:bid/guests/:gid/welcome/signup`,
    "redirect_uri_guestwelcometwofactor": `guest/business/:bid/guests/:gid/welcome/two-factor`,
    "redirect_uri_guestwelcomehome": `guest/business/:bid/guests/:gid/welcome/home?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly`,
    "redirect_uri_guestwelcometwofactorwithparams": `guest/business/:bid/guests/:gid/welcome/two-factor?uuid=:uuid&name=:name&gravatar=:gravatar&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly`,
    "redirect_uri_guestwelcomeplaid": `guest/business/:bid/guests/:gid/welcome/plaid`,
    "redirect_uri_guestwelcomesuccess": `guest/business/:bid/guests/:gid/welcome/success`,
    "redirect_uri_guestwelcomesuccesswechat": `${window.location.origin}/guest/business/:bid/guests/:gid/welcome/success?&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly`,

    "redirect_uri_guestpurchase": `guest/business/:bid/guests/:gid/purchases/:pid`,

    "redirect_uri_guestsignuphooser": `guest/business/:bid/guests/:gid/signup/chooser`,



}



const prod = false;
const backup = false;
let FacepayWhich;
let Auth0Which;
if ( prod) {

    if ( backup ) {
        FacepayWhich = FacepayBackupProd;

    } else {
        FacepayWhich = FacepayProd;

    }
    Auth0Which = Auth0Prod;

} else {
    if ( backup ) {
        FacepayWhich = FacepayBackupDev;

    } else {
        FacepayWhich = FacepayDev;
    }
    Auth0Which = Auth0Dev;


}
export const Facepay = FacepayWhich;
export const Auth0 = Auth0Which;


export const Google = {
    analytics: 'UA-64860131-3',
}


export const FacepayText = {

    activateQrUrl: Facepay.domain + "/activate/:bid",
    welcomeQrLinkUrl: `${window.location.origin}/qr/:uuid`,
    welcomeQrUrl: `${window.location.origin}/guest/business/:bid/guests/0x0/welcome?bname=:bname&bgravatar=:bgravatar`,
    welcomeRedirectQrUrl: `${window.location.origin}/guest/business/:bid/guests/0x0/redirect?bname=:bname&bgravatar=:bgravatar&redeem=:redeem&demo=:demo&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&dropoff=:dropoff&amazon=:amazon&purchase=:purchase&updatebank=:updatebank&subscription=:subscription&bitly=:bitly`,
    welcomeQrOnlyUrl: Facepay.domain + "/guest/business/:bid/guests/0x0/welcome",
    welcomeOnlyUrl2: Facepay.domain + "/guest/business/:bid/guests/0x0/welcome",
    homeOnlyUrl: "/guest/business/:bid/guests/0x0/home?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly",
    welcomeOnlyUrl: Facepay.domain + "/guest/business/:bid/guests/0x0/welcome?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly",
    welcomeUrl: Facepay.domain + "/guest/business/:bid/guests/:gid/welcome?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly",
    purchaseUrl: Facepay.domain + "/guest/business/:bid/guests/:gid/purchases/:pid",
    purchaseOnlyUrl: Facepay.domain + "/guest/business/:bid/guests/:gid/purchases/:pid?isPicture=:isPicture&cell=:cell&amount=:amount&note=:note&data=:data&ref=:ref&xUrl=:xUrl&retUrl=:retUrl&cbUrl=:cbUrl&bitly=:bitly&date=:date&bname=:bname&bgravatar=:bgravatar"


}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
