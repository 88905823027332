import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, ActivatedRoute, Router, NavigationExtras} from '@angular/router';
import {from, Observable} from 'rxjs';

import {concatMap, tap} from 'rxjs/operators';
import {Auth0} from '../../environments/environment';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {Auth0SignupService} from '../services/auth0/auth0signup.service';

@Injectable({
    providedIn: 'root'
})
export class Auth0SignupGuard implements CanActivate {

    constructor(private auth: Auth0SignupService, private route: ActivatedRoute,private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {



         return this.auth.isAuthenticated$.pipe(
            tap(loggedIn => {
                console.log('Auth0 Guard: ' + loggedIn);
                if (!loggedIn) {
                    let url = Auth0.redirect_uri_business;


                    if (next.params) {
                        Object.entries(next.params).forEach(
                            ([key, value]) => {
                                console.log(key, value)
                                url = url.replace(':' + key, value)

                            }
                        );
                    }

                    if ( next.queryParams) {
                        Object.entries(next.queryParams).forEach(
                            ([key, value]) => {
                                console.log(key, value)
                                url = url.replace(':' + key, value)

                            }
                        );
                    }


                    console.log(url)
                    this.auth.login(url, next.params, next.queryParams);

                    //this.auth.login(url);

                    return false;

                }
            })
        );

    }

}