import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { IonicStorageModule } from '@ionic/storage';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import {CurrencyPipe,TitleCasePipe, UpperCasePipe} from '@angular/common';

import {ProgressBarModule} from 'angular-progress-bar';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Auth0CallbackComponent } from './public/auth0-callback/auth0-callback.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {HttpInterceptorService} from './services/http.interceptor.service';
import { TextMaskModule } from 'angular2-text-mask';
import {StatusComponent} from './private/components/status/status.component';
import {SelectPaymentComponent} from './desktop/components/popovers/selectpayment/selectpayment.component';
@NgModule({
  declarations: [AppComponent, Auth0CallbackComponent, StatusComponent, SelectPaymentComponent],
  entryComponents: [StatusComponent,SelectPaymentComponent],
  imports: [
      BrowserModule,
      FormsModule,
      ScrollingModule,
      ReactiveFormsModule,
    BrowserAnimationsModule,
      ProgressBarModule,
      HttpClientModule,
      TextMaskModule,

    IonicModule.forRoot({
        scrollPadding: false,
        scrollAssist: false
    }),
    AppRoutingModule,
      IonicStorageModule.forRoot({
          name: '__facepaydb',
          driverOrder: ['indexeddb', 'sqlite', 'websql']
      })
  ],

  providers: [
      {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true
      },
    StatusBar,
    CurrencyPipe,
    SplashScreen,
      TitleCasePipe,
      UpperCasePipe,
      InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule {}
