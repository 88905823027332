import { Component, OnInit } from '@angular/core';
import { CustomAlert } from 'ionic-custom-alert';

export interface StatusData {
    msg: string;
    icon: string
}

/*

export interface SampleAlertData {
  email: string;
}

 */

@Component({
    selector: 'status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss']
})
export class StatusComponent extends CustomAlert<StatusData> implements OnInit {

    constructor() {
      super();
    }

    ngOnInit() {}

}