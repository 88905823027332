import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, ActivatedRoute, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {Auth0BusinessService} from '../services/auth0/auth0business.service';
import {Auth0, Facepay} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class Auth0GuestChargeGuard implements CanActivate {

    constructor(private auth: Auth0BusinessService, private route: ActivatedRoute) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean|UrlTree> | boolean {
        return this.auth.isAuthenticated$.pipe(
            tap(loggedIn => {
                    console.log("Auth0GuestChargeGuard Guard: " + window.location.href)
                    //console.log("Auth0GuestChargeGuard Guard: " + this.router.url )
                    console.log("Auth0GuestChargeGuard Guard: " + loggedIn);
                    if (!loggedIn) {


                        let url = Auth0.redirect_uri_guestwelcomechooser;
                        url = Facepay.domain +'/business/:bid/guests/:gid/charge';

                        console.log(url)

                        if ( next.params) {
                            Object.entries(next.params).forEach(
                                ([key, value]) => {
                                    console.log(key, value)
                                    url = url.replace(':' + key, value)

                                }
                            );
                        }
                        if ( next.queryParams) {
                            Object.entries(next.queryParams).forEach(
                                ([key, value]) => {
                                    console.log(key, value)
                                    url = url.replace(':' + key, value)

                                }
                            );
                        }


                        console.log( " Auth0GuestChargeGuard final url:  " +  url)

                        this.auth.login(url, next.params, next.queryParams);
//                    this.router.navigate(['guest/error'], {skipLocationChange: true});
                    }
                })
        );
    }

}
